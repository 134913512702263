<template>
  <span>
    <el-tag size="mini" type="success" v-if="item.status === 'ACTIVE'">{{ $t('Active') }}</el-tag>
    <el-tag size="mini" type="info" v-else-if="item.status === 'INACTIVE'">{{ $t('Inactive') }}</el-tag>
    <el-tag size="mini" type="danger" v-else-if="item.status === 'DELETED'">{{ $t('Deleted') }}</el-tag>
    <el-tag size="mini" type="primary" v-else>{{ item.status }}</el-tag>
  </span>
</template>

<script>
export default {
  props: {
    item: Object,
  }
}
</script>