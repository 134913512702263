<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="erp/charge-code" :height="window.height - 310" tableSize="sm" :columns="columns" :baseFilters="{isShowDeleted: isShowDeleted}" v-loading="isLoading" @edit="openModalForView">
          <template #button>
            <el-switch
              v-model="isShowDeleted"
              active-text="Show Deleted"
              inactive-text=""
              class="mr-1" @change="reload()">
            </el-switch>

            <b-button variant="info" class="mr-1" @click="openModalForCreate">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">{{ $t("Charge Code") }}</span>
            </b-button>
          </template>
          <template #cell(status)="row">
            <ChargeCodeStatusTag :item="row.item"/>
          </template>
          <template #cell(actions)="row">
            <span v-if="row.item.status !== 'DELETED'">
                <el-link icon="el-icon-edit" @click="openModalForEdit(row.item)">{{$t('Edit')}}</el-link>
                <el-link icon="el-icon-delete" @click="openModalForDelete(row.item)">{{$t('Delete')}}</el-link>
            </span>
            <el-link icon="el-icon-search" @click="showAudits(row.item)">{{$t('Audits')}}</el-link>
          </template>
        </AdvanceTable>
    </section>

  <el-dialog
    class="compact"
    :title="$t(title)"
    :visible.sync="isShow"
  >
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Type')">
          <el-radio v-model="row.type" label="AR">AR</el-radio>
          <el-radio v-model="row.type" label="AP">AP</el-radio>
          <el-radio v-model="row.type" :label="null">Unspecified</el-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Category')">
          <el-radio v-model="row.category" label="CBO">CBO</el-radio>
          <el-radio v-model="row.category" label="Fulfillment">Fulfillment</el-radio>
          <el-radio v-model="row.category" label="Others">Others</el-radio>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code')">
          <el-input v-model="row.accountCode" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Code')">
          <el-input v-model="row.code" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Level 1')">
          <el-input v-model="row.level1" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Level 2')">
          <el-input v-model="row.level2" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Remarks')">
          <el-input v-model="row.remark" ref="input" type="textarea"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Status')">
          <el-switch
            v-model="row.status"
            active-text="Active" active-value="ACTIVE"
            inactive-text="Inactive" inactive-value="INACTIVE">
          </el-switch>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="info" class="btn mr-2" @click="isShow=false;$refs.advanceTable.loadList()">
          {{ $t('Cancel') }}
        </b-button>
        <b-button variant="primary" class="btn" @click="save()">
          {{ $t('Save') }}
        </b-button>
      </div>
    </div>
  </el-dialog>

  <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { getErpProfile } from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import ChargeCodeStatusTag from "@/views/erp/components/ChargeCodeStatusTag.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";
import _ from "lodash";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    ChargeCodeStatusTag,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "type", modelName: "type", label: "Type", width: "100", filtertype: "select", "options": {'AP': 'AP', 'AR': 'AR'}, sortable: true },
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "150", filtertype: "input", sortable: true },
        { key: "code", modelName: "code", label: "Code", width: "250", filtertype: "input", sortable: true },
        { key: "category", modelName: "category", label: "Category", width: "250", filtertype: "input", sortable: true },
        { key: "level1", modelName: "level1", label: "Level 1", width: "250", filtertype: "input", sortable: true },
        { key: "level2", modelName: "level2", label: "Level 2", width: "250", filtertype: "input", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "100", filtertype: "select", "options": {'INACTIVE': 'Inactive', 'ACTIVE': 'Active'}, sortable: true },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "240" },
      ],
      productList: [],
      isShow: false,
      title: '',
      row: {},

      isShowDeleted: true,
      isLoading: false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reload() {
      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      });
    },
    openModalForView(item) {
      console.log(item);
      this.$alert(item.remark, 'Remark: ' + item.code);
    },
    openModalForCreate() {
      Object.keys(this.row).forEach(key => (this.row[key] = ''));
      this.isShow = true
      this.action = 'CREATE'
      this.title = 'Add Charge Code'
    },
    openModalForEdit(item) {
      this.row = _.clone(item);
      this.isShow = true
      this.action = 'EDIT'
      this.title = 'Edit Charge Code'
    },
    openModalForDelete(item) {
      this.$confirm('Confirm delete "' + item.code + '"?', 'Delete Item').then(() => {
        axios.deletr(
          apis.erpChargeCodes + "/" + item.id,
        ).then(response => {
          this.reload();
          this.promptInfo("Item Deleted");
        }).catch(e => {
          this.promptError(e);
        })
      }).catch(() => {});
    },
    async save(){
      if (this.action === 'CREATE') {
        try {
          const response = await axios.post(
            apis.erpChargeCodes,
            this.row
          );
          await this.reload();
          this.isShow = false

          this.promptInfo('Charge Code created successfully');
        } catch (e) {
          this.promptError(e);
        }
      }else{
        try {
          const response = await axios.put(
            apis.erpChargeCodes+'/'+this.row.id,
            this.row
          );
          await this.reload();
          this.isShow = false

          this.promptInfo('Charge Code updated successfully');
        } catch (e) {
          this.promptError(e);
        }
      }
    },

    showAudits(entity) {
      this.$refs.auditTrail.show({objectId: entity.id, className: 'com.kerrylogistics.dashboard.entities.erp.ChargeCode', name: 'Charge Code'});
    }
  }
};
</script>
